import axios from 'axios'
import Link from 'anchor-link'
import {
    useToast
} from 'vue-toastification'
const toast = useToast()
import {
    isWebUri
} from 'valid-url';
import BrowserTransport from 'anchor-link-browser-transport'
var laccount = null
if (localStorage.getItem("wax_user") != null) {
    laccount = localStorage.getItem("wax_user")
}

const getImgUrl = (hash, isVideo) => {
    if (isWebUri(hash)) {
        return hash;
    }
    let url = 'http://ipfs.io/ipfs/' + hash;
    if (isVideo) {
        return url
    }
    return '//images.weserv.nl/?url=' + url + "&w=300&h=300";
}
const link = new Link({
    transport: new BrowserTransport({
        requestStatus: false
    }),
    chains: [{
        chainId: process.env.VUE_APP_CHAIN_ID,
        nodeUrl: process.env.VUE_APP_CHAIN_FULL_URL,
    }],
})
const setStake = async(dataParams, onSuccess, onErrorCallback) => {
    laccount = localStorage.getItem("wax_user");
    try {
        const actionData = {
            account: "atomicassets",
            name: 'transfer',
            authorization: [{
                actor: laccount,
                permission: 'active',
            }],
            data: dataParams,
        }
            return await doSign(actionData, onSuccess, onErrorCallback)
    } catch (err) {
        return onErrorCallback(err)
    }
}
// const getByFactoryId = async (activeUser, callBack, onError) => {
//     return await axios
//         .post(process.env.VUE_APP_CHAIN_API +"/get_table_rows" ,{
//         json: true, 
//         scope: activeUser, 
//         table: "token.a", 
//         code: "eosio.nft.ft", 
//         table_key: "id", 
//         limit: 100 
//     }).then(async(res) => {
//         if (res.rows.length > 0) {
//             return res;
//         }
//         return [];
//     }).then((res) => callBack(res)).catch(onError)
// }
const getOwnNfts = async (activeUser, callBack, onError) => {
    return await axios
        .post(process.env.VUE_APP_CHAIN_API +"/get_table_rows" ,{
        json: true, 
        scope: activeUser, 
        table: "token.a", 
        code: "eosio.nft.ft", 
        table_key: "id", 
        limit: 100 
    }).then(async(res) => {
        if (res.data.rows.length > 0) {
            // return res.data.rows.filter(item => (item.token_factory_id > 1332 && item.token_factory_id < 1383));
            return res.data.rows.filter(item => (item.token_factory_id == 2314 ));
        }
        return [];
    }).then((res) => callBack(res)).catch(onError)
}

const doSign = async(action, onSuccessCallback, onErrorCallback) => {
    await link.restoreSession(process.env.VUE_APP_NAME).then((session) => {
        session.transact({
            actions: (action instanceof Array) ? action : [action]
        }, {
            blocksBehind: 1,
            expireSeconds: 120,
        }).then(onSuccessCallback).catch(onErrorCallback)
    }).catch(async(error) => {
        const identity = await link.login(process.env.VUE_APP_NAME)
        const {
            session
        } = identity
        session.transact({
            actions: (action instanceof Array) ? action : [action]
        }, {
            blocksBehind: 1,
            expireSeconds: 120,
        }).then(onSuccessCallback).catch(onErrorCallback)
        return error;
    })
}

const isLogin = () => {
    // check local store and also expiry of session
    if (localStorage.getItem('login-user')) {
        return true;
    } else {
        return false
    }
}
const transaction = async(dataParams, onSuccessCallback) => {
    laccount = localStorage.getItem("wax_user");
    try {
        const actionData = {
            account: dataParams.contract,
            name: dataParams.action,
            authorization: [{
                actor: laccount,
                permission: 'active',
            }],
            data: dataParams.data,
        }
            return await doSign(actionData, onSuccessCallback, (error) => {
                error = errorFormat(error);
                toast.error(error);
            })
    } catch (err) {
        let error = errorFormat(err);
        toast.error(error);
    }
}
const errorFormat = (error) => {
    console.log(error.message, typeof error);
    if (typeof error == "string") {
        console.log(error);
        return "Something went wrong."
    }
    if (!error.error && error.message) {
        return error.message
    }
    if (error.error.what) {
        if (error.error.details) {
            return error.error.details[0].message
        }
        return error.error.what;
    } else {
        return "Transaction failed,try again."
    }
}

const successMsg = (msg) => {
    if (msg) {
        toast.success(msg);
    } else {
        toast.success("Transaction successfull");
    }
}
const info = (msg) => {
    if (msg) {
        toast.info(msg);
    } else {
        toast.info("Transaction successfull");
    }
}
export default {
    successMsg,
    errorFormat,
    doSign,
    isLogin,
    transaction,
    getOwnNfts,
    setStake,
    info,
    getImgUrl,
}