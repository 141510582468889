<template>
  <div class="wrapper">
    <div class="logo-wrapper">
      <a href="#">
        <img src="/images/ultra.png" class="img-fluid" alt="" />
      </a>
    </div>
    <div class="main-content">
      <div class="para-content">
        <h6 class="text-primary">
          login for the tap process of your ultra energy gold icon
        </h6>
      </div>
      <div class="text-center mt-4">
        <button class="button pulse">Connects your Ultra Wallet</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.logo-wrapper a {
  display: flex;
  align-items: center;
  text-transform: uppercase;
}
.logo-wrapper img {
  width: 38%;
  margin-right: 30px;
}
.para-content {
  margin-top: 3rem;
}
.para-content h6 {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 900;
}
</style>