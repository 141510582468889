<template>
  <div class="container">
    <div class="wrapper">
      <div class="page-info">
        <div class="top-nav">
          <div class="top-nav_logo">
            <a href="#">
              <span>
                <img src="/images/ultra.png" class="img-fluid" alt="" />
              </span>
            </a>
          </div>
          <div class="top-nav_btn">
            <button class="button dis-btn">Disconnect your Wallet</button>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-5 offset-1">
            <div class="left-side">
              <div class="left-side_content">
                <h1 class="step-title">Step 1</h1>
                <p>
                  step one is to claim your ultra energy gold coin.This process
                  will result in the staking your nft after filling in your
                  deliver address data.We will not use this data for commercial
                  or other purpose.<br />If you have several nft, please repeat
                  the operation.
                </p>
                <div class="text-center">
                  <router-link to="/step-1" class="button text-uppercase">
                    claim my gold Coin
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-1">
            <div class="border-left"></div>
          </div>
          <div class="col-md-5">
            <div class="left-side borderRight">
              <div class="left-side_content">
                <h1 class="step-title">Step 2</h1>
                <p>
                  Step two consists of authenticating your piece once it has
                  been received.<br />
                  Step one is absolutely necessary before moving on to step
                  two.<br />Once the authentication is sucessful , your stacked
                  NFT will be burned.<br />
                  You will then receive two NFTs in your wallet.<br />
                  The first will represent the advantages linked to the coin.<br />
                  The second will represent your physical piece including its
                  certificate of authenticity.<br />The deadline for sending the
                  latter will be longer.
                </p>

                <div class="text-center mt-4">
                  <router-link to="/step-2" class="button text-uppercase">
                    authenticate my gold coin
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
import Footer from "../components/footer.vue";
export default {
  name: "Home",
  components: {
    Footer,
  },
  mounted() {
    const isUltraWalletInstalled = window.ultra && typeof window.ultra === "object";
    console.log(isUltraWalletInstalled);
  },
};
</script>

<style scoped>
.page-info {
  width: 62vw;
  position: relative;
}

.button {
  padding: 8px 12px;
  text-transform: uppercase;
  border: 2px solid blue !important;
}

.left-side {
  text-align: center;
}
.left-side_content {
  padding: 15px 34px;
}
/* .borderRight::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 2px;
  border: 3px solid blue;
} */
.border-left {
  border-left: 3px solid blue;
  height: 100%;
}
p {
  font-size: 12px;
  font-weight: 900;
  height: 203px;
}

.step-title {
  font-size: 3rem;
  font-weight: 900;
  margin-bottom: 37px;
  text-transform: uppercase;
}
</style>