<template>
  <div class="container">
    <div class="wrapper">
      <div class="page-info">
        <div class="top-nav">
          <div class="top-nav_logo">
            <a href="#">
              <span>
                <img src="/images/ultra.png" class="img-fluid" alt="" />
              </span>
            </a>
          </div>
          <div class="top-nav_btn">
            <button class="button dis-btn">Disconnect your Wallet</button>
          </div>
        </div>
        <div class="table-wrapper">
          <h3 class="text-blue text-center small fw-bold mb-3">
            Admin Interface
          </h3>
          <table class="table table-bordered border-dark">
            <thead>
              <tr>
                <th scope="col">Uniqs Id</th>
                <th scope="col">Status</th>
                <th scope="col">Photo</th>
                <th scope="col">Validation authentication</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td scope="row">Ultra energy gold 1</td>
                <td class="bg-warning">locked</td>
                <td>check micro photo</td>
                <td>
                  <div class="d-flex align-items-center mt-2">
                    <div class="checkbox-wrapper">
                      <div class="checkbox-group">
                        <input type="radio" id="yes" name="radio-group" />
                        <label for="yes">Yes</label>
                      </div>
                      <div class="checkbox-group mt-1">
                        <input type="radio" id="no" name="radio-group" />
                        <label for="no">No</label>
                      </div>
                    </div>

                    <button class="button">validate</button>
                  </div>
                </td>
              </tr>
              <tr>
                <td scope="row">Ultra energy gold 2</td>
                <td class="bg-red">unlocked</td>
                <td>check micro photo</td>
                <td>
                  <div class="d-flex align-items-center mt-2">
                    <div class="checkbox-wrapper">
                      <div class="checkbox-group">
                        <input type="radio" id="yes" name="radio-group" />
                        <label for="yes">Yes</label>
                      </div>
                      <div class="checkbox-group mt-1">
                        <input type="radio" id="no" name="radio-group" />
                        <label for="no">No</label>
                      </div>
                    </div>

                    <button class="button">validate</button>
                  </div>
                </td>
              </tr>
              <tr>
                <td scope="row">Ultra energy gold 2</td>
                <td class="bg-success">complete / success</td>
                <td>check micro photo</td>
                <td>
                  <div class="d-flex align-items-center mt-2">
                    <div class="checkbox-wrapper">
                      <div class="checkbox-group">
                        <input type="radio" id="yes" name="radio-group" />
                        <label for="yes">Yes</label>
                      </div>
                      <div class="checkbox-group mt-1">
                        <input type="radio" id="no" name="radio-group" />
                        <label for="no">No</label>
                      </div>
                    </div>

                    <button class="button">validate</button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminDashboard",
};
</script>

<style scoped>
.table-wrapper {
  width: 34vw;
  margin: auto;
}
.table {
  vertical-align: middle !important;
}

.table-wrapper .table,
th,
td {
  border: 2px solid #000 !important;
  text-align: center;
}
.table-wrapper th {
  font-size: 11px;
}
.table-wrapper td {
  font-size: 12px;
  padding: 27px 12px !important;
}
.table-wrapper th,
td {
  padding: 16px 0 !important;

  font-weight: 900;
}
.table-wrapper td {
  padding: 0px 18px;
}
.table-wrapper .button {
  border: 2px solid var(--borderColor);
  padding: 2px 20px;
  margin-left: 10px;
}
.top-nav {
  margin-bottom: 2rem;
  justify-content: space-between;
}
.top-nav_logo {
  flex: inherit;
  width: inherit;
  display: inherit;
  text-align: center;
}
.top-nav .top-nav_logo a {
  margin-left: 0;
}
</style>