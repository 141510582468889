<template>
  <div
    class="upload-file-wrapper"
    @dragover="dragover"
    @dragleave="dragleave"
    @drop="dropHandler"
  >
    <input
      type="file"
      multiple
      name="file"
      id="fileInput"
      class="hidden-input d-none"
      @change="previewImages"
      ref="file"
      accept=".pdf,.jpg,.jpeg,.png"
    />
    <label for="fileInput" class="upload-file">
      <div class="h-50" v-if="isDragging">Release to drop files here.</div>
      <div class="d-flex flex-column align-items-center" v-else>
        <span class="small-text text-blue upload-text">
          Drag files here to upload
        </span>
        <img src="/images/gallery.png" alt="gallery" class="upload-img" />
        <i class="bi bi-upload text-dark"></i>
      </div>
    </label>
    <div class="img-preview-container" v-if="previews.length">
      <div class="d-flex flex-wrap justify-content-center">
        <div
          class="mb-2 position-relative"
          v-for="(image, index) in previews"
          :key="index"
        >
          <img :src="image" class="preview-img" />
          <span class="close-img pointer" @click="deletePreview(index)"
            >&times;</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isDragging: false,
      files: [],
      previews: [],
    };
  },
  methods: {
    onChange() {
      this.files = [...this.$refs.file.files];
    },
    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    drop(e) {
      e.preventDefault();
      this.previewImages(e.dataTransfer);
      this.isDragging = false;
    },
    deletePreview(index) {
      this.previews.splice(index, 1);
    },
    dropHandler(event) {
      event.preventDefault();
      this.previewImages(event.dataTransfer);
    },
    previewImages(event) {
      let files = event.files || event.target.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const reader = new FileReader();
        reader.onload = (e) => {
          this.previews.push(e.target.result);
          this.isDragging = false;
        };
        reader.readAsDataURL(file);
      }
    },
  },
};
</script>

<style scoped>
label {
  cursor: pointer;
}
.upload-file-wrapper {
  width: 172px;
  border: 2px dashed black;
  margin: 10px auto;
  padding: 5px 0;
}
.upload-file {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.upload-file-wrapper .upload-img {
  width: 50px;
  margin: 10px;
}
.upload-file-wrapper .upload-text {
  font-size: 12px;
  text-align: center;
  line-height: 1;
  padding: 0 20px;
}
.upload-file-wrapper i {
  font-size: 45px;
}
.img-preview-container {
  height: 50px;
  overflow: auto;
}
.preview-container button {
  font-size: 12px;
  border: none;
  background: transparent;
}
.preview-container p {
  font-size: 12px;
}

.preview-card {
  display: flex;
  border: 1px solid #a2a2a2;
  padding: 5px;
  margin-left: 5px;
  align-items: center;
}

.preview-img {
  width: 35px;
  margin: 0 4px;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #a2a2a2;
  background-color: #a2a2a2;
}
.close-img {
  position: absolute;
  right: -1px;
  top: 0px;
  background: green;
  color: #fff;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
</style>