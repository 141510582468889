<template>
  <router-view></router-view>
</template>

<script>
import router from "../router";
export default {
  name: "mainView",
  data() {
    return {
      gameOver: null,
      hoursLeft: null,
      account: null,
      currentRoute: null,
      expiredDate: null,
      winningToken: 0,
    };
  },
  mounted() {
    // validate session on mounting page
    if (!localStorage.getItem("login-user")) {
      router.push("/login");
    }
  },
  methods: {
    logout: function () {
      router.push("/login");
    },
  },
};
</script>