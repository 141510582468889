import {
    createRouter,
    createWebHistory
} from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import ApiService from '../services'
import mainView from '../components/Main'
import LoginPage from '../views/LoginPage.vue'
import ClaimNft from "../views/ClaimNft.vue"
import StepOne from '../views/StepOne.vue'
import StepTwo from '../views/StepTwo.vue'
import AdminLogin from '../views/AdminLogin.vue'
import AdminDashboard from '../views/AdminDashboard.vue'

const routes = [{
        path: '/',
        redirect: '/dashboard',
        component: mainView,
        // dashboard auth guard
        beforeEnter(to, from, next) {
            if (!isLogin()) {
                next('/login')
            } else {
                next()
            }
        },
        children: [{
            path: '/dashboard',
            name: 'dashboard',
            props: true,
            component: Home,
            meta: {
                sideBarKey: 'Dashboard'
            }
        }]
    },
    {
        path: '/login',
        name: 'Login',
        // props: false,
        component: Login
    },
    {
        path: '/loginPage',
        name: 'loginPage',
        // props: false,
        component: LoginPage
    }, {
        path: '/claim-nfts',
        name: 'claim-nfts',
        // props: false,
        component: ClaimNft
    },
    {
        path: '/step-1',
        name: 'StepOne',
        // props: false,
        component: StepOne,
    },
    {
        path: '/step-2',
        name: 'StepTwo',
        // props: false,
        component: StepTwo,
    },
    {
        path: '/admin-login',
        name: 'AdminLogin',
        // props: false,
        component: AdminLogin,
    },
    {
        path: '/admin-dashboard',
        name: 'AdminDashboard',
        // props: false,
        component: AdminDashboard,
    },
]
const isLogin = function () {
    return ApiService.isLogin()
}
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router