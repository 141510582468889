<template>
  <div class="container">
    <div class="wrapper">
      <div class="page-info">
        <div class="top-nav">
          <div class="back-btn">
            <router-link to="/claim-nfts" class="text-black">
              <i class="bi bi-arrow-return-left"></i>
              <span class="mx-3">back</span>
            </router-link>
          </div>
          <div class="top-nav_logo">
            <a href="#">
              <span>
                <img src="/images/ultra.png" class="img-fluid" alt="" />
              </span>
            </a>
          </div>
          <Disconnect />
        </div>

        <div class="main-content p-5">
          <div class="row">
            <div class="col-md-6">
              <h6 class="title mb-0">
                You own X Ultra Energy Gold Uniqs in stacking
              </h6>
              <h6 class="title">
                Please choose a Uniq you need to authenticate
              </h6>
              <div class="left-side mt-4">
                <div class="box-content">
                  <div class="box-content_img">
                    <img src="/images/gallery.png" alt="" />
                  </div>
                  <div class="box-content_text">
                    <h6>UniqueID<span>:xxx</span></h6>
                    <h6>FactoryID<span>:xxx</span></h6>
                  </div>
                </div>
                <div class="box-content">
                  <div class="box-content_img">
                    <img src="/images/gallery.png" alt="" />
                  </div>
                  <div class="box-content_text">
                    <h6>UniqueID<span>:xxx</span></h6>
                    <h6>FactoryID<span>:xxx</span></h6>
                  </div>
                </div>
                <div class="box-content">
                  <div class="box-content_img">
                    <img src="/images/gallery.png" alt="" />
                  </div>
                  <div class="box-content_text">
                    <h6>UniqueID<span>:xxx</span></h6>
                    <h6>FactoryID<span>:xxx</span></h6>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="right-side">
                <div class="right-side_content text-center">
                  <span class="para-text top-text">
                    Please upload serval macro photos of your piece from all
                    angles. <a href="#" class="text-danger">click here</a> for a
                    example
                  </span>
                  <upload-file />
                  <span class="small-text text-blue d-block my-2">or</span>
                  <button class="button">select file</button>
                  <div class="checkbox-wrapper mt-2">
                    <input type="radio" id="test1" name="radio-group" />
                    <label for="test1"
                      >I certify the coin i received is the coin i have in my
                      possession</label
                    >
                  </div>
                  <button class="button">
                    Make my authentication request file
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  </div>
</template>
  
  <script>
import Disconnect from "../components/Disconnect.vue";
import Footer from "../components/footer.vue";
import UploadFile from "../components/UploadFile.vue";
export default {
  components: {
    Footer,
    UploadFile,
    Disconnect
  },
  data() {
    return {
      isDragging: false,
      files: [],
    };
  },
  methods: {
    onChange() {
      this.files = [...this.$refs.file.files];
      console.log(this.files);
    },
    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    drop(e) {
      e.preventDefault();
      this.$refs.file.files = e.dataTransfer.files;
      this.onChange();
      this.isDragging = false;
    },
    remove(i) {
      this.files.splice(i, 1);
    },
  },
};
</script>
  
  <style scoped>
label {
  cursor: pointer;
}
.back-btn span {
  font-size: 12px;
  font-weight: 900;
}

.top-text {
  display: block;
  text-align: center;
  width: 180px;
  margin: auto;
}
.top-nav {
  margin-bottom: 0;
  justify-content: space-between;
}
.top-nav .top-nav_logo a {
  margin-left: 0;
}
.top-nav_logo {
  flex: inherit;
  width: inherit;
  display: inherit;
  text-align: center;
}
.left-side {
  overflow-y: scroll;
  height: 230px;
  padding: 24px;
}
.box-content {
  margin-bottom: 1rem;
}
.title,
.box-content_text h6 {
  color: blue;
  font-size: 10px;
  font-weight: 900;
}

.box-content_text h6 {
  margin: 1px 10px;
}
.left-side .box-content .box-content_img img {
  width: 110px;
}
.left-side .box-content {
  display: flex;
  align-items: center;
}

.left-side::-webkit-scrollbar {
  width: 18px;
  border-radius: 5px;
}

/* Track */
.left-side::-webkit-scrollbar-track {
  background: #eee;
  box-shadow: inset 0px 0px 1px rgba(0, 0, 0, 0.4);
  border-radius: 1px;
}

/* Handle */
.left-side::-webkit-scrollbar-thumb {
  background: rgb(206, 206, 206);
  border-radius: 1px;
}

/* Handle on hover */
.left-side::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.right-side .button {
  border: 2px solid var(--borderColor);
  padding: 4px 30px;
}

.checkbox-wrapper label {
  font-size: 12px;
  color: #000 !important;
  font-weight: 900;
  line-height: 1;
}
</style>