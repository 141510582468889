<template>
  <div class="container">
    <div class="wrapper">
      <div class="page-info">
        <div class="top-nav">
          <router-link to="/claim-nfts" class="text-black">
            <i class="bi bi-arrow-return-left"></i>
            <span class="mx-3">back</span>
          </router-link>
          <div class="top-nav_logo">
            <a href="#">
              <span>
                <img src="/images/ultra.png" class="img-fluid" alt="" />
              </span>
            </a>
          </div>
          <Disconnect />
        </div>
        <h6 class="title">You own X Ultra Energy Gold Uniqs</h6>
        <div class="main-content p-5">
          <div class="row" v-if="!loading && data.length>0">
            <div class="col-md-6">
              <div class="left-side mt-4">
                <div class="box-content" v-for="(item,index) in data" :key="index">
                  <a @click.prevent="selectNft(item.id)" :class="selectedCards.includes(item.id)?'selected':''">
                    <div class="box-content_img">
                      <img src="/images/gallery.png" class="card-img" alt="" />
                    </div>
                    <div class="box-content_text card-info">
                      <h6>UniqueID<span>:{{item.id}}</span></h6>
                      <h6>FactoryID<span>:{{item.token_factory_id}}</span></h6>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="right-side">
                <div class="table">
                  <div class="table-inputField">
                    <input type="text" placeholder="Email address" v-model="email" />
                  </div>
                  <div class="table-inputField">
                    <input type="text" placeholder="Name" v-model="name" />
                  </div>
                  <div class="table-inputField">
                    <input type="text" placeholder="Family Name" v-model="familyName"/>
                  </div>
                  <div class="table-inputField">
                    <input type="text" placeholder="Street and Number" v-model="street" />
                  </div>
                  <div class="table-inputField">
                    <input type="text" placeholder="City" v-model="city" />
                  </div>
                  <div class="table-inputField">
                    <input type="text" placeholder="Post Code" v-model="postCode" />
                  </div>
                  <div class="table-inputField">
                    <input type="text" placeholder="Province / Area" v-model="area" />
                  </div>
                  <div class="table-inputField">
                    <input type="text" placeholder="Country" v-model="country" />
                  </div>
                  <div class="table-inputField">
                    <input type="text" placeholder="Phone Number" v-model="phone" />
                  </div>
                </div>
                <div class="right-side_content mt-3">
                  <p class="para-text">
                    By pressing the button bellow , you agree to burn your
                    selected Ultra Energy Gold Uniq.<br />
                    You will receive Uniq Energy Gold advantage.<br />
                    You will receive a shipping tracking number by email.<br />
                    The coin will be shipped to your address in a secure
                    package.<br />
                    When authenticated your wallet will receive a certificate of
                    authenticity Uniq
                  </p>
                  <button class="button" @click="burn()">Burn my Uniq</button>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-else>
              <div class="left-side mt-4">
                No Uniq Found
              </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
var ultra = window.ultra;
import Footer from "../components/footer.vue";
import Disconnect from "../components/Disconnect.vue";
import ApiService from "@/services";
export default {
  components: {
    Footer,
    Disconnect
  },
   data() {
    return {
      data: [],
      loading: true,
      selectedCards:[]
    };
  },
  methods: {
    selectNft(tokenId){
      this.selectedCards.push(tokenId);
    },
    async burn(){
      console.log(this.selectedCards,localStorage.getItem("login-user"));
    let txObject =  {
        action: "burn",
        contract: "eosio.nft.ft",
        data: {
          memo: "burn-000",
          token_ids: Array.from(this.selectedCards),
          from: localStorage.getItem("login-user"),
        }
      }
      console.log(txObject);
      try {
        const response = await ultra.signTransaction(txObject);
        console.log(response);
        response.data.transactionHash;
      } catch (err) {
        console.log(err);
      }
    }
  },
  mounted() {
    ApiService.getOwnNfts(localStorage.getItem('login-user'),(r)=>{
      this.data = r;
      this.loading=false;
      console.log(r);
    });
  },
};
</script>

<style scoped>
.back-btn span {
  font-size: 12px;
  font-weight: 900;
}
.selected{
  border: 2px solid rgba(13,110,253,.25);
  padding: 10px;
  text-decoration: none;
}
.top-nav {
  margin-bottom: 0;
}
.top-nav .top-nav_logo a {
  margin-left: 0;
}
.left-side {
  overflow-y: scroll;

  height: 421px;
  padding: 24px;
}
.box-content {
  margin-bottom: 1rem;
}
.title,
.box-content_text h6 {
  color: blue;
  font-size: 12px;
  font-weight: 900;
}

.box-content_text h6 {
  margin: 1px 10px;
}
.card-img {
  width: 110px;
}
.left-side .box-content {
  display: flex;
  align-items: center;
}
/* .left-side .box-content::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: #000;
  width: 30px;
  height: 30px;
} */

.left-side::-webkit-scrollbar {
  width: 18px;
  border-radius: 5px;
}

/* Track */
.left-side::-webkit-scrollbar-track {
  background: #eee;
  box-shadow: inset 0px 0px 1px rgba(0, 0, 0, 0.4);
  border-radius: 1px;
}

/* Handle */
.left-side::-webkit-scrollbar-thumb {
  background: rgb(206, 206, 206);
  border-radius: 1px;
}

/* Handle on hover */
.left-side::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.right-side .table {
  border: 2px solid black;
  width: 270px;
  margin: auto;
}
.table .table-inputField {
  /* border-bottom: 1px solid black; */
  padding: 4px 8px;
}
.table .table-inputField input {
  font-size: 12px;
  font-weight: 500;
  color: var(--primaryColor);
  width: 100%;
  border: none;
}
.table .table-inputField input:focus {
  outline: none;
}
.table .table-inputField input::placeholder {
  color: var(--primaryColor);
}

.right-side_content button {
  padding: 6px 45px;
  display: block;
  margin: 10px auto;
  border: 2px solid var(--primaryColor);
}
.card-info > h6{
  float: right;
}
</style>