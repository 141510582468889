<template>
  <div class="top-nav_btn">
    <button class="button dis-btn" @click="logout">Disconnect your Wallet</button>
  </div>
</template>

<script>
import router from "../router";
export default {
  name: "Disconnect",
  methods: {
    logout: async function() {
      const isUltraWalletInstalled = window.ultra && typeof window.ultra === "object";
      if(isUltraWalletInstalled){
        try {
          const log = await window.ultra.disconnect();
          console.log(log);
          setTimeout(() => {
            router.push("/login");
          }, 2000);
        } catch (err) {
          // { status: "error", message: "Forbidden" }
        }
      }
    },
  },
};
</script>