<template>
  <footer>
    <div class="terms-condition">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" href="#">terms and condition</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">contact us</a>
        </li>
      </ul>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
.navbar-nav {
  flex-direction: row;
  margin-top: 4rem;
  border-bottom: 1px solid var(--borderColor);
}
.navbar-nav .nav-link {
  margin-right: 20px;
  font-weight: 900;
  font-size: 10px;
  text-transform: uppercase;
}
</style>

