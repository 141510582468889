<template>
  <div class="wrapper">
    <div class="logo-wrapper">
      <a href="#">
        <img src="/images/ultra.png" class="img-fluid" alt="" />
      </a>
    </div>
    <div class="main-content">
      <div class="para-content">
        <h6 class="text-primary">
          login for the tap process of your ultra energy gold icon
        </h6>
      </div>
      <div class="text-center mt-4">
        <button class="button pulse" @click="handle_login()">Connects your Ultra Wallet</button>
      </div>
    </div>
  </div>
</template>

<script>
var ultra = window.ultra;
// @ is an alias to /src
export default {
  name: "Login",
  data() {
    return {
      ual: null,
      showPopup: false,
      noExtension: true,
    };
  },
  inheritAttrs: false,
  mounted() {
    localStorage.clear();
  },
  methods: {
    handle_login: function () {
      const isUltraWalletInstalled = window.ultra && typeof window.ultra === "object";
    if(isUltraWalletInstalled){
      ultra
        .connect()
        .then((response) => {
          var accountID = response.data?.blockchainid.split('@')[0];
          localStorage.setItem('login-user', accountID);
          localStorage.setItem('eagerlyConnection', true);
          window.location.href = '/dashboard'
        })
        .catch((response) => {
          console.log(response);
        });
      }else{
        console.log(ultra,window.ultra);
        alert("you are required to install ultra wallet extension for chrome")
      }
    },
  },
};
</script>

<style scoped>
.logo-wrapper a {
  display: flex;
  align-items: center;
  text-transform: uppercase;
}
.logo-wrapper img {
  width: 38%;
  margin: 0 auto;
}
.para-content {
  margin-top: 3rem;
}
.para-content h6 {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 900;
}
</style>