<template>
  <div class="wrapper">
    <div class="logo-wrapper">
      <a href="#">
        <img src="/images/ultra.png" class="img-fluid" alt="" />
      </a>
    </div>
    <div class="main-content">
      <div class="para-content">
        <h6 class="text-primary text-center my-4">Admin Interface</h6>
      </div>
      <div class="text-center mt-4">
        <button class="button pulse" @click="handle_login()">
          Connects your Ultra Wallet
        </button>
      </div>
    </div>
  </div>
</template>
  
  <script>
var ultra = window.ultra;
// @ is an alias to /src
export default {
  name: "AdminLogin",
  data() {
    return {
      ual: null,
      showPopup: false,
      noExtension: true,
    };
  },
  inheritAttrs: false,
  mounted() {
    if (!window.ultra) {
      const errorMsg =
        "> The Ultra Wallet Extension is not installed, refresh the page to try again";
      alert(errorMsg);
      document
        .querySelectorAll("button.btn")
        .forEach((b) => b.setAttribute("disabled", true));
    } else {
      localStorage.clear();
      this.noExtension = false;
    }
  },
  methods: {
    handle_login: function () {
      if (!this.noExtension) {
        ultra
          .connect()
          .then((response) => {
            var accountID = response.data?.blockchainid.split("@")[0];
            localStorage.setItem("login-user", accountID);
            localStorage.setItem("eagerlyConnection", true);
            window.location.href = "/dashboard";
          })
          .catch((response) => {
            console.log(response);
          });
      } else {
        alert("you are required to install ultra wallet extension for chrome");
      }
    },
  },
};
</script>
  
  <style scoped>
.logo-wrapper a {
  display: flex;
  align-items: center;
  text-transform: uppercase;
}
.logo-wrapper img {
  width: 24%;
  margin: 0 auto;
}

.para-content h6 {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 900;
}
</style>